import React, { useState, useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./how-it-works.css"
import previous from "../images/previous.svg"
import next from "../images/next.svg"

class Work extends React.Component {
  render() {
    const { title, subtitle, images, phone } = this.props
    return (
      <div className={`how-it-works-container`}>
        <div
          className="client-box"
          style={{ display: "block", position: "relative" }}
        >
          <div className="client-title">{title}</div>
          <div className="client-title client-subtitle">{subtitle}</div>
          <BackgroundImage Tag="div" className="phone-container" fluid={phone}>
            {images.map((img, i) => (
              <Img
                fluid={images[i]}
                alt="How It Works"
                className="how-it-works-slide "
                style={
                  this.props.current === i || this.props.images.length === 1
                    ? { opacity: 1 }
                    : {}
                }
              />
            ))}
          </BackgroundImage>
        </div>
      </div>
    )
  }
}

class WorkMobile extends React.Component {
  state = { active: 0 }
  shows = [0, 0, 0, 1, 2, 2, 2]
  prevs = { 0: 4, 1: 4, 2: 4, 3: 0, 4: 3, 5: 3, 6: 3 }
  nexts = { 0: 3, 1: 3, 2: 3, 3: 4, 4: 0, 5: 0, 6: 0 }

  timerMove() {
    this.setState({
      active: this.state.active === 6 ? 0 : this.state.active + 1,
    })
  }

  componentWillMount() {
   this.interval = setInterval(() => this.timerMove(), 3000)
  }

  change(x) {
    //clearInterval(this.interval)
    if (x === -1) this.setState({ active: this.prevs[this.state.active] })
    else this.setState({ active: this.nexts[this.state.active] })
   // this.interval = setInterval(() => this.timerMove(), 3000)
  }
  render() {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }} className="only-mobile-tablet">
          <img
            src={previous}
            className="prev-icon icon-works"
            onClick={() => this.change(-1)}
          />
        </div>
        <div style={{ flex: "7 0 228px" }}>
          <div className="how-it-works-container-mobile">
            {this.props.components.map((item, i) => (
              <div
                className="how-it-works-slide2"
                style={this.shows[this.state.active] == i ? { opacity: 1 } : {}}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div style={{ flex: "1" }} className="only-mobile-tablet">
          <img
            src={next}
            className="next-icon icon-works"
            onClick={() => this.change(1)}
          />
        </div>
      </div>
    )
  }
}

class Works extends React.Component {
  state = { current: 0 }

  change2() {
    this.setState(({ current }) => ({
      current: current === 2 ? 0 : current + 1,
    }))
  }

  componentWillMount() {
    this.interval = setInterval(() => this.change2(), 3000)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            phone: file(relativePath: { eq: "phone_back.png" }) {
              childImageSharp {
                fluid(maxHeight: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works11: file(relativePath: { eq: "works11.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works12: file(relativePath: { eq: "works12.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works13: file(relativePath: { eq: "works13.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works2: file(relativePath: { eq: "works2.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works31: file(relativePath: { eq: "works31.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works32: file(relativePath: { eq: "works32.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            works33: file(relativePath: { eq: "works33.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          const works = [
            <Work
              title="POST AN OFFER"
              subtitle="Fully customizable"
              current={this.state.current}
              phone={data.phone.childImageSharp.fluid}
              images={[
                data.works11.childImageSharp.fluid,
                data.works12.childImageSharp.fluid,
                data.works13.childImageSharp.fluid,
              ]}
            >
              Suspendisse potenti. Vestibulum et dui sed justo malesuada
              consequat vel eu turpis. Vivamus faucibus lorem.
            </Work>,
            <Work
              title="VERIFIED MODELS"
              subtitle="Claim your offer"
              current={this.state.current}
              phone={data.phone.childImageSharp.fluid}
              images={[data.works2.childImageSharp.fluid]}
            >
              Suspendisse potenti. Vestibulum et dui sed justo malesuada
              consequat vel eu turpis. Vivamus faucibus lorem.
            </Work>,
            <Work
              title="GET ORGANIC RESULTS"
              subtitle="Reach a vast audience"
              current={this.state.current}
              phone={data.phone.childImageSharp.fluid}
              images={[
                data.works31.childImageSharp.fluid,
                data.works32.childImageSharp.fluid,
                data.works33.childImageSharp.fluid,
              ]}
            >
              Suspendisse potenti. Vestibulum et dui sed justo malesuada
              consequat vel eu turpis. Vivamus faucibus lorem.
            </Work>,
          ]
          return (
            <div>
              <div className="works-container only-desktop">{works}</div>
              <div className="works-container only-mobile-tablet">
                <WorkMobile components={works} />
              </div>
              <div
                className="dot-container only-mobile-tablet"
                style={{ display: "none" }}
              >
                {[0, 1, 2].map(i => (
                  <div
                    className={`small-dot ${
                      this.state.current !== i ? "inactive-dot" : ""
                    }`}
                    onClick={() => this.setState({ current: i })}
                  />
                ))}
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default Works
