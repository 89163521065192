import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Input from "react-phone-number-input/input"
import Img from "gatsby-image"
import CountUp from "react-countup"
import fetchPonyfill from "fetch-ponyfill"
import "./brands.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import elevate from "../images/elevate.svg"
import visibility from "../images/visibility.svg"
import previous from "../images/previous.svg"
import next from "../images/next.svg"
import revenue from "../images/revenue.svg"
import doGood from "../images/do-good.svg"
import Benefit from "../components/benefit"
import Client from "../components/client"
import DesktopClient from "../components/desktop-client"
import Slider from "../components/slider"
import Works from "../components/how-it-works"
import Modal from "../components/modal"

const BrandStats = ({ stats }) => (
  <>
    <div className="only-desktop-tablet">
      {stats.map(item => (
        <>
          <div className="case-study-info-title">{item.name}</div>
          <div className="case-study-info-subtitle">{item.data}</div>
        </>
      ))}
    </div>
    <div className="only-mobile">
      {stats.map(item => (
        <>
          <div className="case-study-info-title" style={{ marginTop: "16px" }}>
            {item.name}: <span style={{ color: "#555555" }}>{item.data}</span>
          </div>
        </>
      ))}
    </div>
  </>
)

const { fetch } = fetchPonyfill()

class BrandsForm extends React.Component {
  state = {
    data: {
      name: "",
      contact_name: "",
      contact_email: "",
      phone_number: "",
    },
    errors: {},
    sent: false,
    modal: false,
    sending: false,
  }

  formItem = (name, field, placeholder) => (
    <>
      <div className="contact-form-label brands-label">
        {name}
        {this.state.errors[field] ? (
          <span className="form-error">{this.state.errors[field]}</span>
        ) : null}
      </div>
      {field === "phone_number" ? (
        <Input
          defaultCountry="US"
          className="contact-form-input contact-small-input brands-input"
          value={this.state.data[field]}
          placeholder={placeholder}
          onChange={e => this.setField(field, e)}
          style={{ color: "#000", fontSize: "14px" }}
        />
      ) : (
        <input
          type="text"
          className="contact-form-input contact-small-input brands-input"
          placeholder={placeholder}
          value={this.state.data[field]}
          onChange={e => this.setField(field, e)}
          style={{ color: "#000", fontSize: "14px" }}
        ></input>
      )}
    </>
  )

  submit() {
    if (this.state.sending) return
    this.setState({ errors: {}, sent: false, error: false })
    const errors = {}
    if (!this.state.data.name) {
      errors["name"] = "Name is required!"
    }
    if (!this.state.data.contact_name) {
      errors["contact_name"] = "Contact name is required!"
    }
    if (!this.state.data.contact_email) {
      errors["contact_email"] = "Email is required!"
    } else if (!/\S+@\S+\.\S+/.test(this.state.data.contact_email)) {
      errors["contact_email"] = "Email format not correct!"
    }
    if (!this.state.data.phone_number) {
      errors["phone_number"] = "Phone number is required!"
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
      return
    }
    this.setState({ sending: true })
    fetch(`${process.env.GATSBY_API_URL}/leads/apply/business`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.data),
    }).then(data => {
      if (data.status === 201) {
        this.setState({
          sent: true,
        })
      } else this.setState({ error: true })
      this.setState({ sending: false })
    })
  }

  closeModal() {
    this.setState({
      modal: false,
      data: {
        name: "",
        contact_name: "",
        contact_email: "",
        phone_number: "",
      },
    })
  }

  setField(name, e) {
    const value = name === "phone_number" ? e : e.target.value
    this.setState(state => {
      return {
        ...state,
        data: { ...state.data, [name]: value },
      }
    })
  }

  render() {
    return (
      <div>
        {this.props.type === "desktop" ? (
          <Modal
            open={this.state.modal}
            close={() => this.closeModal()}
            key="brands-modal"
          />
        ) : null}
        <div className="flex-grid brands-form-grid">
          {this.state.sent ? (
            <div className="brands-sent-message">
              Your information has been received, and a Neon Coat representative
              will be in contact with you soon!
              <div className="flex-grid">
                <div className="col"></div>
                <div className="col">
                  <div
                    className="brands-call-us"
                    style={{ marginBottom: "15px" }}
                  >
                    Or call us:{" "}
                    <a href="tel:+13477099134" style={{ color: "#555555" }}>
                      <b>(347) 709-9134</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col">
                {this.formItem("Brand Name", "name", "Your Brand")}
                {this.formItem(
                  "Contact Email",
                  "contact_email",
                  "you@yourbrand.com"
                )}
                <div className="brands-submit-container only-desktop-tablet">
                  <div
                    className="contact-submit-button brands-submit"
                    onClick={() => this.submit()}
                  >
                    {this.state.sending ? "SENDING..." : "SUBMIT"}
                  </div>
                </div>
              </div>
              <div className="col">
                {this.formItem("Contact Name", "contact_name", "Your Name")}
                {this.formItem(
                  "Phone Number",
                  "phone_number",
                  "XXX-XXX-XXXX or +XXXXXXXXXXXXX"
                )}
                <div className="brands-call-us">
                  Or call us:{" "}
                  <a href="tel:+13477099134" style={{ color: "#555555" }}>
                    <b>(347) 709-9134</b>
                  </a>
                </div>
                <div className="brands-submit-container only-mobile">
                  <div
                    className="contact-submit-button"
                    style={{ marginTop: "25px" }}
                    onClick={() => this.submit()}
                  >
                    {this.state.sending ? "SENDING..." : "SUBMIT"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

class Brands extends React.Component {
  state = {
    slides1: 1,
  }
  componentWillMount() {
    //setInterval(() => this.change(+1), 3000)
  }

  change(n) {
    if (n > 0)
      this.setState(({ slides1 }) => ({
        slides1: slides1 === 2 ? 1 : slides1 + 1,
      }))
    else
      this.setState(({ slides1 }) => ({
        slides1: slides1 === 1 ? 2 : slides1 - 1,
      }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            image: file(relativePath: { eq: "brands-hero.jpg" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image2: file(relativePath: { eq: "brands-hero2.png" }) {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            client: file(relativePath: { eq: "brands-client.jpg" }) {
              childImageSharp {
                fixed(height: 205) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            client1: file(relativePath: { eq: "client1.png" }) {
              childImageSharp {
                fixed(height: 405) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            client2: file(relativePath: { eq: "client2.png" }) {
              childImageSharp {
                fixed(height: 405) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            client3: file(relativePath: { eq: "client3.png" }) {
              childImageSharp {
                fixed(height: 405) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            casestudy1: file(relativePath: { eq: "casestudy1.png" }) {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            casestudy2: file(relativePath: { eq: "casestudy2.png" }) {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            allThirdPartyStats {
              nodes {
                followers
                models
              }
            }
          }
        `}
        render={data => (
          <Layout page="brands">
            <SEO title="Brands" description="Elevate your brand by connecting with the world’s top models." />
            <div className="flex-grid brands-grid">
              <div
                style={{ flex: "1 1 169px" }}
                className="brands-box only-desktop"
              />
              <div
                style={{ flex: "0 0 74px" }}
                className="brands-box only-tablet"
              />
              <div className="only-mobile"></div>
              <div className="brands-text brands-box">
                <div className="vertical-center no-mob-center">
                  <div className="brands-top-text">
                    Establish and enhance your brand by connecting with the
                    world’s top models, reaching a collective following of{" "}
                    <span className="model-count" style={{}}>
                      <CountUp
                        end={data.allThirdPartyStats.nodes[0].followers}
                        separator=","
                        duration={1.5}
                      />
                      <noscript>129,149,438</noscript>
                    </span>{" "}
                    consumers.
                  </div>
                  <div className="brands-text only-desktop-tablet get-connected">
                    Get connected.
                  </div>
                  <div className="only-desktop-tablet">
                    <BrandsForm type="desktop" />
                  </div>
                </div>
              </div>
              <div className="col brands-box" />
              <div className="brands-hero-image only-tablet">
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  alt="Neon Coat Brands"
                />
              </div>
              <div className="brands-hero-image only-desktop-mobile">
                <Img
                  fluid={data.image.childImageSharp.fluid}
                  alt="Neon Coat Brands"
                />
              </div>
              <div className="col brands-box only-mobile">
                <div className="brands-text only-mobile get-connected">
                  Get connected.
                </div>
                <BrandsForm type="mobile" />
              </div>
            </div>

            <div className="flex-grid">
              <div style={{ flex: "0 1 1000px", marginTop: "40px" }}>
                <div
                  className="flex-grid benefit-grid"
                  style={{ flexWrap: "wrap" }}
                >
                  <Benefit title1="ELEVATE" title2="YOUR BRAND" icon={elevate}>
                    Beautiful re-postable Instagram content and in-person
                    appearances from {data.allThirdPartyStats.nodes[0].models}{" "}
                    of the world’s elite influencers elevates your brand
                  </Benefit>
                  <Benefit
                    title1="INCREASE"
                    title2="VISIBILITY"
                    icon={visibility}
                  >
                    Reach a loyal and growing audience of followers looking for
                    new trends—track your success with daily analytics reports
                  </Benefit>
                  <Benefit title1="BOOST" title2="REVENUE" icon={revenue}>
                    Connect directly to an elite network—attract valuable
                    customers and upsell clients
                  </Benefit>
                  <Benefit title1="DO" title2="GOOD" icon={doGood}>
                    Align yourself with a female-founded brand that promotes
                    social good, model independence and ethical business
                    practices
                  </Benefit>
                </div>
              </div>
            </div>
            <div className="flex-grid">
              <div
                style={{
                  flex: "0 1 1000px",
                  marginTop: "40px",
                  marginBottom: "50px",
                }}
              >
                <div className="brands-title-big">What Our Clients Say</div>{" "}
                <div style={{ display: "flex", maxWidth: "100vw" }}>
                  <div style={{ flex: "1" }} className="only-mobile-tablet">
                    <img
                      src={previous}
                      className="prev-icon"
                      onClick={() => this.change(-1)}
                    />
                  </div>

                  <div className="clients-container only-mobile-tablet">
                    <Client
                      business="MY/MO MOCHI ICE CREAM"
                      name="Angie Thomas"
                      active={this.state.slides1 === 1}
                      image={data.client1.childImageSharp.fixed}
                    >
                      Neon Coat is a one-of-a-kind network that complements our
                      online and offline marketing efforts, seamlessly
                      connecting influencers, social media, and experiential.
                    </Client>
                    <Client
                      business="HIT HOUSE"
                      name="Dana VanPamelen"
                      active={this.state.slides1 === 2}
                      image={data.client2.childImageSharp.fixed}
                    >
                      Neon Coat is simple, easy and a great value-add to our
                      business. Its technology is unparalleled and the user
                      experience is seamless. Plus the customer service is
                      outstanding—the staff has gone above and beyond for us
                      multiple times.
                    </Client>
                  </div>

                  <div className="desktop-clients-container only-desktop">
                    <Slider>
                      <DesktopClient
                        business="My/Mo Mochi Ice Cream"
                        name="ANGIE THOMAS"
                        color={true}
                        image={data.client1.childImageSharp.fixed}
                        active={this.state.slider === 2}
                      >
                        Neon Coat is a great way for models to connect with
                        amazing New York establishments, brands and most
                        importantly, each other. It can be difficult as a model
                        when you’re new in the city, and Neon Coat is a nice way
                        of bringing the community together.
                      </DesktopClient>
                      <DesktopClient
                        business="Hit House"
                        name="DANA VANPEMELEN"
                        color={true}
                        image={data.client2.childImageSharp.fixed}
                        active={this.state.slider === 1}
                      >
                        Neon Coat is simple, easy and a great value-add to our
                        business. Its technology is unparalleled and the user
                        experience is seamless. Plus the customer service is
                        outstanding —the staff has gone above and beyond for us
                        multiple times.
                      </DesktopClient>

                      <DesktopClient
                        business="GLO Skin Beauty"
                        name="BRETT GALLAGHER"
                        color={true}
                        image={data.client3.childImageSharp.fixed}
                        active={this.state.slider === 3}
                      >
                        Neon Coat’s app provides easy access to content creators
                        and reporting metrics for the content they create. It
                        really is a great community building platform with a
                        great user experience.
                      </DesktopClient>
                    </Slider>
                  </div>

                  <div style={{ flex: "1" }} className="only-mobile-tablet">
                    <img
                      src={next}
                      className="next-icon"
                      onClick={() => this.change(+1)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-grid"
              style={{
                backgroundColor: "#d3edef",
                width: "auto",
                position: "relative",
              }}
            >
              <div id="how-it-works" className="href-works" />
              <div style={{ flex: "0 1 1000px", marginTop: "40px" }}>
                <div
                  className="brands-title-big"
                  style={{ marginBottom: "40px" }}
                >
                  How It Works
                </div>
                <Works />
              </div>
            </div>
            <div className="flex-grid" style={{}}>
              <div
                style={{
                  flex: "0 1 900px",
                  marginTop: "40px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="brands-title-big provide-title"
                  style={{ marginBottom: "40px" }}
                >
                  We Provide
                </div>{" "}
                <div
                  className="flex-grid provide-grid"
                  style={{
                    margin: "0px 50px",
                    fontSize: "12px",
                  }}
                >
                  <div className="col">
                    <div className="provide-content">
                      + Gifting / Seeding Campaigns
                      <br /> + Daily In-Person Marketing
                      <br />+ Neon Coat Event Sponsorships
                      <br /> + Brand Event Enhancement
                    </div>
                  </div>
                  <div className="col">
                    <div className="provide-content">
                      + Modeling Jobs
                      <br />
                      + Paid Social Content
                      <br /> + Brand Ambassadors
                      <div className="and-more">...and more!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grid" style={{}}>
              <div style={{ flex: "0 1 1000px", marginTop: "40px" }}>
                <div className="brands-dots"></div>
                <div
                  className="brands-title-big"
                  style={{ marginBottom: "40px" }}
                >
                  Case Studies
                </div>{" "}
                <div
                  className="flex-grid case-study-container"
                  style={{ marginBottom: "60px" }}
                >
                  <div style={{ flex: "1" }}>
                    <Img
                      fluid={data.casestudy1.childImageSharp.fluid}
                      className="case-study-image image-pad"
                      alt="Neon Coat Brands"
                    />
                  </div>
                  <div
                    style={{ flex: "1" }}
                    className="case-study-info case-study-break"
                  >
                    <div className="case-study-title">BRAND</div>
                    <div className="case-study-box">
                      <BrandStats
                        stats={[
                          { name: "BRAND", data: "NatureLab Tokyo" },
                          { name: "GIFT", data: "Haircare" },
                          { name: "MODELS", data: "66" },
                          { name: "POSTS", data: "174" },
                          { name: "AUDIENCE", data: "1.5M" },
                        ]}
                      />
                    </div>
                  </div>

                  <div style={{ flex: "1" }}>
                    <Img
                      fluid={data.casestudy2.childImageSharp.fluid}
                      className="case-study-image"
                      alt="Neon Coat Brands"
                    />
                  </div>
                  <div style={{ flex: "1" }} className="case-study-info">
                    <div className="case-study-title">BRICK + MORTAR</div>
                    <div className="case-study-box">
                      <BrandStats
                        stats={[
                          { name: "BRAND", data: "Three Jewels" },
                          { name: "TIME RANGE", data: "12 Months" },
                          { name: "MODELS", data: "437" },
                          { name: "POSTS", data: "1602" },
                          { name: "AUDIENCE", data: "8.4M" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        )}
      />
    )
  }
}
export default Brands
